import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Banner from "../../components/home/Banner";
import { ALL_SHOP_CARDS, getNFTInfoById } from "../../utils/nft";
import { toFormattedBalance } from "../../utils/conversion";
import { Heading3, Typo3 } from "../../components";
import Column from "../../components/Column";
import Row from "../../components/Row";
import Spacer from "../../components/Spacer";
import CardCarrousel from "../../components/CardCaroussel";
import { BrowserView, MobileView } from "react-device-detect";

const HomeScreen = () => {
  const myRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  const [activeCard, setActiveCard] = useState(getNFTInfoById(1));
  const handleSetActiveCard = (cardId: number) => {
    const card = getNFTInfoById(cardId);
    setActiveCard(card);
  };

  useEffect(() => {
    if (hash === "#about" && myRef.current) {
      myRef.current.scrollIntoView();
    }
  }, [hash]);

  return (
    <div style={{ paddingTop: 62 }}>
      <Banner />

      <section className="diffrent-chain" ref={myRef}>
        <div className="container">
          <div className="diffrent-chain-inner">
            <div className="diff-chain-left">
              <h2>Unlocking global capital with FS</h2>
              <p>
                Our decentralized investment app takes care of all the regulatory and compliance requirements, ensuring your project attracts the widest variety of investors with minimal effort. Launch a successful, compliant, and audited IDO, IEO or INO today with FS.
              </p>
              <a
                href="https://form.typeform.com/to/LdPnpf6n"
                className="common-btn gradiant-btn d-none hide-mobile"
              >
                Apply for funding
              </a>
            </div>
            <div className="right-logos">
              <ul>
                <li>
                  <img src="/images/Bitcoin_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/Heco_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/Ethereum_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/Polygon_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/BSC_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/xDai_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/Fantom_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/Fusion_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <img src="/images/Avalange_logo-white 80_.svg" alt="" />
                </li>
                <li>
                  <span className="And-all-EVM-networks">
                    And&nbsp;all&nbsp;other&nbsp;EVM&nbsp;networks
                  </span>
                </li>
              </ul>
            </div>
            <div className="text-center mobile-btn-bottom">
              <a
                href="https://form.typeform.com/to/LdPnpf6n"
                className="common-btn gradiant-btn"
              >
                Start raising funds
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bottom-background">
        <section className="tear-outer">
          <div className="container">
            <div className="tear-inner">
              <div className="tear-header">
                <h2>Get serious about investment with our Investment Tiers.</h2>
                <p>
                  The protocol uses tier based system to determine the
                  guaranteed <br /> allocation for each participant in a pool.
                </p>
              </div>

              <BrowserView>
                <Row style={{ width: "100%" }}>
                  <CardCarrousel
                    cards={ALL_SHOP_CARDS}
                    getActiveCard={handleSetActiveCard}
                  />
                  <Column style={{ flex: 1, marginLeft: "2rem" }}>
                    <Spacer size="xs" />
                    <Heading3>{activeCard.name} NFT</Heading3>
                    <Typo3>Tier {activeCard.tier}</Typo3>
                    <Spacer />
                    <Heading3>
                      {" "}
                      {activeCard.tickets === 100
                        ? "Guaranteed"
                        : activeCard.tickets + "X"}
                    </Heading3>
                    <Typo3>Lottery tickets</Typo3>
                    <Spacer />
                    <Heading3>
                      {activeCard.weight === 0
                        ? "TBD"
                        : activeCard.weight + "X"}
                    </Heading3>
                    <Typo3>Pool weight</Typo3>
                    <Spacer />
                    <Heading3>
                      {toFormattedBalance(activeCard.price)[0] + " FS"}
                    </Heading3>
                    <Typo3>Price</Typo3>
                    <Spacer />
                    <a
                      href="https://app.fantomstarter.io/store"
                      className="common-btn gradiant-dark-btn btn-purchase"
                    >
                      Purchase
                    </a>
                    <a
                      href="https://app.fantomstarter.io/store"
                      className="link-purchase"
                    >
                      Compare all NFT keys
                    </a>
                  </Column>
                </Row>
              </BrowserView>

              <MobileView>
                <Row style={{ width: "100%" }}>
                  <CardCarrousel
                    cards={ALL_SHOP_CARDS}
                    getActiveCard={handleSetActiveCard}
                  />
                </Row>
                <Row
                  style={{
                    width: "100%",
                    justifySelf: "end",
                    marginTop: "40px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Column
                    style={{
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spacer size="xs" />
                    <Heading3>{activeCard.name} NFT</Heading3>
                    <Typo3>Tier {activeCard.tier}</Typo3>
                    <Spacer />
                    <Heading3>
                      {" "}
                      {activeCard.tickets === 100
                        ? "Guaranteed"
                        : activeCard.tickets + "X"}
                    </Heading3>
                    <Typo3>Lottery tickets</Typo3>
                    <Spacer />
                    <Heading3>
                      {activeCard.weight === 0
                        ? "TBD"
                        : activeCard.weight + "X"}
                    </Heading3>
                    <Typo3>Pool weight</Typo3>
                    <Spacer />
                    <Heading3>
                      {toFormattedBalance(activeCard.price)[0] + " FS"}
                    </Heading3>
                    <Typo3>Price</Typo3>
                    <Spacer />
                    <a
                      href="https://app.fantomstarter.io/store"
                      className="common-btn gradiant-dark-btn btn-purchase"
                    >
                      Purchase
                    </a>
                    <a
                      href="https://app.fantomstarter.io/store"
                      className="link-purchase"
                    >
                      Compare all NFT keys
                    </a>
                  </Column>
                </Row>
              </MobileView>
            </div>
          </div>
        </section>
        <section className="time-line-bg">
          {/* <video
              playsInline autoPlay muted loop
              poster="/assets/images/fstimeLine.png"
              className="videoDown"
              id="bgviddown">
              <source src="/videos/bg-down.mp4" type="video/mp4" />
          </video> */}

          <section className="road-map">
            <div className="container">
              <div className="road-map-header text-center">
                <h2>
                  Big things ahead,
                  <br />
                  Roadmap for 2025
                </h2>
                <br />
                <div className="road-map-text">
                  <span>
                    Make the most of blockchain opportunities and drastically increase user adoption with our web3 fundraising platform. Enjoy an optimal user experience and benefit from enhanced security that will keep your user data safe.
                  </span>
                </div>
              </div>
              <div className="road-map-outer">
                <div className="rm_pbox">
                  <span>Q1</span>
                  <p>
                    Rebrand $FS with Sonic Mainnet
                  </p>
                </div>
                <div className="rm_pbox">
                  <span>Q2</span>
                  <p>
                    New products and verticals expansion.
                  </p>
                </div>
                <div className="rm_pbox">
                  <span>Q3</span>
                  <p>Bridge the traditional and web3 markets</p>
                </div>
              </div>
            </div>
          </section>
          <section className="advisor">
            <div className="container">
              <div className="advidsor-inner">
                <div className="advisor-right">
                  <img src="/images/Marco-crop.png" alt="" />
                </div>
                <div className="advisor-left">
                  <h2>Our advisors will help you unlock your potential</h2>
                  <p>
                    We carefully selected our advisors and partners, so it
                    enables you to navigate the rapidly evolving blockchain industry.
                    <br />
                    <br />
                    Please contact our remote global sales team to receive more
                    information on how FantomStarter can help your
                    business.
                  </p>
                  <h5>Marco Robustelli</h5>
                  <p>CBO at ANKR</p>
                  <Link to="/team" className="link-text link-team">
                    Show the whole team&nbsp;
                    <svg
                      className="HoverArrow"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      aria-hidden="true"
                    >
                      <g fill-rule="evenodd">
                        <path
                          className="HoverArrow__linePath"
                          d="M0 5h7"
                        ></path>
                        <path
                          className="HoverArrow__tipPath"
                          d="M1 1l4 4-4 4"
                        ></path>
                      </g>
                    </svg>
                  </Link>
                </div>

                <Link to="/team" className="link-text link-team mobile-link">
                  Show the whole team&nbsp;
                  <svg
                    className="HoverArrow"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    aria-hidden="true"
                  >
                    <g fill-rule="evenodd">
                      <path className="HoverArrow__linePath" d="M0 5h7"></path>
                      <path
                        className="HoverArrow__tipPath"
                        d="M1 1l4 4-4 4"
                      ></path>
                    </g>
                  </svg>
                </Link>
              </div>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};
export default HomeScreen;
