import styles from "./team.module.css";
import data from "./data.json";

const TeamPage = () => {
  return (
    <div className={styles.teamPage}>
      <div className={styles.content}>
        <div className={styles.mainHeading}>
          <h2>FantomStarter team</h2>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.mobileWrap}`}
        >
          <p>We grow stronger everyday with each learning.</p>
          <button className={styles.commonBtn}>Contact Us</button>
        </div>
        <div className={`d-flex flex-wrap ${styles.teamOuter}`}>
          {data.teams.map((team, key) => (
            <div className={styles.team} key={key}>
              <figure className={styles.teamOverlay}>
                <img
                  className={styles.teamImage}
                  src={`/images/team/${team.image}`}
                  alt={team.name}
                />
                <div className={styles.hoverDiv}>
                  <p>{team.detail}</p>
                </div>
              </figure>
              <div className="teamCaption">
                <h3>{team.name}</h3>
                <p>{team.title}</p>
                <div className={`d-flex ${styles.socialLinks}`}>
                  <a href={team.linkedin} target="_blank" rel="noreferrer">
                    Linkedin
                  </a>
                  {/* <a href={`https://twitter.com/${team.twitter}`} target="_blank" rel="noreferrer">Twitter</a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <section className={`${styles.advisorTeam}`}>
        <div className="container">
          <div className="advidsor-inner">
            <div className="advisor-right">
              <img src="/images/Marco.png" alt="" />
            </div>
            <button className={styles.commonBtn}>Contact Us</button>
            <div className="advisor-left">
              <h2>Our advisors will help you unlock your potential</h2>
              <p>
                Please contact our remote global sales team to receive more
                information on how FantomStarter can help your business.
              </p>
              <h5>Marco Robustelli</h5>
              <p>CBO at ANKR</p>
              <div className={`d-flex ${styles.socialLinks}`}>
                <a
                  href="https://www.linkedin.com/in/marco-robustelli/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
                {/* <a href="/" target="_blank" rel="noreferrer">Twitter</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.logosBottom}>
        <h2 className={`${styles.LogosHeading}`}>These teams can confirm it</h2>
        <div className={`d-flex ${styles.btLogo}`}>
          <div className={styles.logoList}>
            <img src="/images/logo-signum-capital.png" alt="" />
          </div>
          <div className={styles.logoList}>
            <img src="/images/logo-any-swap.png" alt="" />
          </div>
          <div className={styles.logoList}>
            <img src="/images/logo-spark.png" alt="" />
          </div>
          <div className={styles.logoList}>
            <img src="/images/zbs_capital.png" alt="" />
          </div>
          <div className={styles.logoList}>
            <img src="/images/logo-ankr.svg" alt="" />
          </div>
        </div>
      </section>
    </div>
  );
};
export default TeamPage;
