import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container-lg">
        <div className="footer-top">
          <img src="/images/logo-footer.jpg" alt="" />
          <div className="ft-top-right">
            <a href="https://app.fantomstarter.io/" className="show-mobile">
              <img
                height="24"
                className="callto-action"
                src="./images/lets-connect.png"
                alt=""
              />
            </a>
            <h5>Let’s connect!</h5>
            <a
              href="https://app.fantomstarter.io/"
              className="common-btn gradiant-btn"
            >
              Start investing
            </a>
          </div>
        </div>
        <div className="ft-middle">
          <div className="ft-links">
            <h5>FantomStarter</h5>
            <ul>
              <li>
                 <Link to="/team">Team</Link>
              </li>
              <li>
                <a
                  href="https://fantomstarterio.github.io/docs/English_Litepaper_v3.0.pdf"
                  title="litepaper"
                >
                  Litepaper
                </a>
              </li>
              <li>
                <a
                  href="https://fantomstarterio.github.io/docs/Audit_Report_SlowMist.pdf"
                  title="audit"
                >
                  Audit
                </a>
              </li>
              <li>
                <a
                  href="https://form.typeform.com/to/yQnDGQUZ"
                  title="scout"
                >
                  Scout program
                </a>
              </li>
            </ul>
          </div>
          <div className="ft-links">
            <h5>Help</h5>
            <ul>
              <li>
                <a href="https://fantomstarter.medium.com/fantomstarter-best-practices-52b9dba3c5f4">
                  FAQ
                </a>
              </li>
              <li>
                <a href="mailto:hello@fantomstarter.io">Contact</a>
              </li>
              <li>
                <a href="https://wellfound.com/company/fsmultichain">
                  Careers
                </a>
              </li>
            </ul>
          </div>
          <div className="ft-links">
            <h5>Socials</h5>
            <ul>
              <li>
                <a href="https://x.com/fantomstarter">Twitter</a>
              </li>
              <li>
                <a href="https://t.me/fantomstarter">Telegram</a>
              </li>
              <li>
                <a href="https://discord.com/invite/nsMxVcNwy6">Discord</a>
              </li>
              <li>
                <a href="https://github.com/fantomstarter">Github</a>
              </li>
              <li>
                <a href="https://medium.com/@fantomstarter">Blog</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="ft-bottom">
          <ul>
            <li>
              <a
                href="https://app.fantomstarter.io/terms"
                rel="noopener noreferrer"
              >
                Terms of service
              </a>
            </li>
            {/* <li><a href="/privacy">Privacy policy</a></li>
              <li><a href="/cookies">Cookie policy</a></li> */}
          </ul>
          <p>FantomStarter © 2021-2024</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
