import styled from "styled-components";

export const Header = styled.div`
  font-family: "NeuzeitOfficeW04";
  background-color: ${(props) => props.theme.color.white};
  height: ${(props) => props.theme.topBarSize}px;
  padding: 0 40px;
  box-shadow: 0 1px 1px 0 #e2e2e2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.fontColor.primary};
  z-index: 10;
`;

export const Body = styled.div`
  font-family: "NeuzeitOfficeW04";
  background-color: ${(props) => props.theme.color.greys.first()};
  color: ${(props) => props.theme.fontColor.primary};
  display: flex;
  font-size: calc(10px + 2vmin);
  min-height: calc(100vh);
  display: flex;
  flex-direction: row;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  button {
    font-family: "Inter";
  }
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 10px;
`;

export const WrapA = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const LinkExt = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: ${(props) => props.theme.color.primary.first()};

  :visited {
    color: ${(props) => props.theme.color.greys.third()};
  }
`;

export const Heading1 = styled.div`
  font-family: "NeuzeitOfficeW04-Regular";
  font-size: 48px;
`;

export const Heading2 = styled.div`
  font-family: "NeuzeitOfficeW04-Regular";
  font-size: 34px;
`;

export const Heading3 = styled.div`
  font-family: "JekoVariable";
  font-size: 24px;
  color: #0c1727;
  line-height: 20px;
`;

export const Heading4 = styled.div`
  font-family: "NeuzeitOfficeW04-Regular";
  font-size: 18px;
`;

export const Heading5 = styled.div`
  font-family: "NeuzeitOfficeW04-Regular";
  font-size: 14px;
`;

export const Typo1 = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.fontColor.secondary};
`;

export const Typo2 = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.fontColor.secondary};
`;

export const Typo3 = styled.div`
  font-family: "NeuzeitOfficeW04-Regular";
  font-size: 12px;
  line-height: 20px;
  color: #4c525b;
`;

export const OverlayButton = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: ${(props) => !props.disabled && "pointer"};
  color: inherit;
`;

export const Button = styled.button<{
  variant: "primary" | "secondary" | "tertiary" | "quaternary";
  padding?: string;
  color?: string;
  fontSize?: string;
  disabled?: boolean;
  width?: string;
}>`
  background: ${(props) =>
    props.variant === "primary"
      ? props.theme.color.primary.third(props.disabled ? 0.6 : 1)
      : props.variant === "secondary"
      ? props.theme.color.secondary.first(props.disabled ? 0.6 : 1)
      : props.variant === "tertiary"
      ? "linear-gradient(90deg,#765cde 0,#df73ec 50%,#f8b16b 99%)"
      : "#e6e6e6"};
  border: none;
  border-radius: 20px;
  color: ${(props) =>
    !props.disabled
      ? props.variant === "quaternary"
        ? "#0c1727"
        : props.color || props.theme.color.primary.second()
      : "#bac3ba"};
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: ${(props) => (props.padding ? props.padding : "12px 24px")};
  transition: 0.2s all;
  width: ${(props) => props.width && props.width};

  ${(props) => props.hidden && "hidden"} :focus {
    border: ${(props) => props.variant === "primary" && "none"};
    outline: none;
  }

  :active {
    transform: scale(0.98);
  }
`;

export const Container = styled.div<{ padding?: string }>`
  border: ${(props) => `1px solid ${props.theme.color.primary.second()}`};
  padding: ${(props) => (props.padding ? props.padding : "2rem")};
  background-color: ${(props) => props.theme.color.greys.second()};
  border-radius: 8px;
`;

export const ContentBox = styled.div<{ padding?: string }>`
  background-color: ${(props) => props.theme.color.greys.third()};
  display: inline-flex;
  padding: ${(props) => (props.padding ? props.padding : "2rem")};
  border-radius: 8px;
`;

export const Card = styled.div<{ padding?: string }>`
  background-color: white;
  display: inline-flex;
  border-radius: 32px;
  box-shadow: 0 10.3px 6.9px 0 rgba(0, 0, 0, 0.08),
    0 33.2px 29.2px 0 rgba(0, 0, 0, 0.13);
`;

export const Input = styled.input`
  flex: 1;
  background-color: white;
  border: none;
  color: #4c525b;
  font-size: 20px;
  max-width: 200px;

  :focus {
    outline: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }
`;

export const TextArea = styled.textarea`
  // font-family: "proxima-nova", sans-serif;
  flex: 1;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  padding-top: 1.5rem;
  height: 170px;
  resize: none;
  line-height: 24px;

  :focus {
    outline: none;
  }
`;
