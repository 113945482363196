import tier1Img from "../assets/nft/tier1_minion.png";
import tier2Img from "../assets/nft/tier2_imp.png";
import tier3Img from "../assets/nft/tier3_oni.png";
import tier4Img from "../assets/nft/tier4_banshee.png";
import tier5Img from "../assets/nft/tier5_wraith.png";
import tier6Img from "../assets/nft/tier5_wraith.png";
import tier7Img from "../assets/nft/tier7_king.png";

export const ALL_CARDS = [1, 2, 3, 4, 5, 6, 7];
export const ALL_SHOP_CARDS = [1, 2, 3, 4, 5];
export const ALL_CARD_INFO = {
  0: {
    img: null,
    name: "No tier",
    tier: 0,
    tickets: 0,
    weight: 0,
    price: 0,
    max: 0,
  },
  1: {
    img: tier1Img,
    name: "Minion",
    tier: 1,
    tickets: 1,
    weight: 1,
    price: 13500,
    max: 500,
  },
  2: {
    img: tier2Img,
    name: "Imp",
    tier: 2,
    tickets: 2,
    weight: 1.5,
    price: 26100,
    max: 250,
  },
  3: {
    img: tier3Img,
    name: "Oni",
    tier: 3,
    tickets: 4,
    weight: 2.25,
    price: 50400,
    max: 150,
  },
  4: {
    img: tier4Img,
    name: "Banshee",
    tier: 4,
    tickets: 8,
    weight: 3.25,
    price: 99000,
    max: 100,
  },
  5: {
    img: tier5Img,
    name: "Wraith",
    tier: 5,
    tickets: 100,
    weight: 4.5,
    price: 409500,
    max: 50,
  },
  6: {
    img: tier6Img,
    name: "Kappa",
    tier: 6,
    tickets: 100,
    weight: 9,
    price: 742500,
    max: 35,
  },
  7: {
    img: tier7Img,
    name: "King",
    tier: 7,
    tickets: 100,
    weight: 27,
    price: 2025000,
    max: 15,
  },
} as any;

export const getNFTInfoById = (id: number) => {
  return ALL_CARD_INFO[id];
};
