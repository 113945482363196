import { black, white } from "./colors";

const theme = {
  fontFamily: 'NeuzeitOfficeW04-Regular',
  borderRadius: 12,
  breakpoints: {
    mobile: 576,
    tablet: 768,
    desktop: 992,
    ultra: 1200,
  },
  fontColor: {
    primary: black,
    secondary: "#4c525b",
  },
  color: {
    white,
    black,
    primary: {
      first: (opacity: number = 1) => `#F3F3F3`, // #lightgrey
      second: (opacity: number = 1) => `#F3F3F3`, // #darkgrey
      third: (opacity: number = 1) => `#0C1727`, // #black
      fourth: (opacity: number = 1) => `#765CDE`, // #purple
      fifth: (opacity: number = 1) => `#DF73EC`, // #pink
      sixth: (opacity: number = 1) => `#FCBA57`, // #orange
    },
    secondary: {
      first: () => `#3AB8FE`, // #blue
      second: () => `rgba(0, 0, 0, 0.08)`,
    },
    greys: {
      first: (opacity: number = 1) => `#eaeaea`,
      second: (opacity: number = 1) => `#c0c0c1`, // #B7BECB
      third: (opacity: number = 1) => `#9d9d9d`, // #B7BECB
      fourth: (opacity: number = 1) => `#848485`, // #202F49; // #707B8F
    },
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 60,
};

export default theme;
