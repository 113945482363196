import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomeScreen from './screens/home';
import TeamPage from './screens/team';
import Header from './components/Header';
import Footer from './components/Footer';
import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import RouteListener from './RouteListener'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {

  return (
    <BrowserRouter>
    <RouteListener/>
      <ThemeProvider theme={theme}>
        <Header />
        <Switch>
          <Route path="/" component={HomeScreen} exact/>
          <Route path="/team" component={TeamPage} exact/>
        </Switch>
      </ThemeProvider>
     
      <Footer />
    </BrowserRouter>
  );
}

export default App;
