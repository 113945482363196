import { getNFTInfoById } from "../../utils/nft";
import Row from "../Row";
import React, { useEffect, useState } from "react";
import Column from "../Column";
import {BrowserView, MobileView} from 'react-device-detect';

const CardCarrousel: React.FC<any> = ({ cards, getActiveCard }) => {
  const [cardsOrder, setCardsOrder] = useState([...cards].reverse());
  const activeCard = getNFTInfoById(cardsOrder[cardsOrder.length - 1]);

  const getCardColor = {
    1: "#ffe075",
    2: "#fcc3d6",
    3: "#333c4a",
    4: "#765cdf",
    5: "#d9d9d9",
  } as any;

  const sortCards = (selectedId: number) => {
    const selectedIndex = cardsOrder.findIndex(
      (id: number) => id === selectedId
    );
    setCardsOrder([
      ...Array(cards.length - 1)
        .fill("fill")
        .map((fill, index) => {
          return cardsOrder[(selectedIndex + index + 1) % cards.length];
        }),
      cardsOrder[selectedIndex],
    ]);

    getActiveCard(selectedId);
  };

  const moveCardsLeft = () => {
    let lastCard = cardsOrder[4];
    lastCard--;
    if (lastCard === 0) {
      lastCard = 5;
    }
    sortCards(lastCard);
  };

  const moveCardsRight = () => {
    let lastCard = cardsOrder[4];
    lastCard++;
    if (lastCard === 6) {
      lastCard = 1;
    }
    sortCards(lastCard);
  };

  useEffect(() => {
    setCardsOrder([...cards].reverse());
  }, [cards]);

  return (
    <>
      <BrowserView>
        <Column
        style={{
          flex: 1,
          position: "relative",
          height: "350px",
          alignItems: "end",
          minWidth: `${180 + 26 * cards.length}px`,
        }}
      >
        {cardsOrder.map((cardId: number, index: number) => {
          return (
            <img
              key={`key-img-${cardId}`}
              style={{
                position: "absolute",
                height: 315 - (40 * (cards.length - 1) - index * 40) + "px",
                left: index * 26,
                top: (cardsOrder.length - 1 - index) * 20,
                opacity: 1 - 0.1 * (cardsOrder.length - 1 - index),
              }}
              src={getNFTInfoById(cardId).img}
              alt=""
            />
          );
        })}
          <Row
            style={{
              justifySelf: "end",
              width: "205px",
              marginTop: "auto",
              gap: ".75rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

            <div
              style={{
                height: "24px",
                width: "25px",
                background: "url(/images/arrow.svg)",
                margin: "3px 0 3px 0px",
                cursor: "pointer",
                transform: "rotate(180deg)"
              }}
              onClick={() => moveCardsLeft()}
            />

            {[...cards].map((cardId: number) => {
              return (
                <div
                  key={`key-link-${cardId}`}
                  style={{
                    height: cardId === activeCard ? "18px" : "18px",
                    width: cardId === activeCard ? "18px" : "18px",
                    borderRadius: "50%",
                    border: cardId === activeCard ? "2px solid white" : "",
                    backgroundColor: getCardColor[cardId],
                    boxShadow: "0 0 10px 1px #e2e2e2",
                    cursor: "pointer",
                  }}
                  onClick={() => sortCards(cardId)}
                />
              );
            })}

            <div
              style={{
                height: "24px",
                width: "25px",
                background: "url(/images/arrow.svg)",
                margin: "3px 0 3px 0px",
                cursor: "pointer",
              }}
              onClick={() => moveCardsRight()}
            ></div>
          </Row>
          </Column>
        </BrowserView>

        <MobileView>
        <Column
        style={{
          flex: 1,
          position: "relative",
          height: "350px",
          alignItems: "end",
          minWidth: `${180 + 26 * cards.length}px`,
        }}
      >
        {cardsOrder.map((cardId: number, index: number) => {
          return (
            <img
              key={`key-img-${cardId}`}
              style={{
                position: "absolute",
                height: 315 - (40 * (cards.length - 1) - index * 40) + "px",
                left: index * 26,
                top: (cardsOrder.length - 1 - index) * 20,
                opacity: 1 - 0.1 * (cardsOrder.length - 1 - index),
              }}
              src={getNFTInfoById(cardId).img}
              alt=""
            />
          );
        })}
          <Row
            style={{
              justifySelf: "end",
              width: "100%",
              marginTop: "330px",
              gap: ".75rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

            <div
              style={{
                height: "24px",
                width: "25px",
                background: "url(/images/arrow.svg)",
                margin: "3px 0 3px 0px",
                cursor: "pointer",
                transform: "rotate(180deg)"
              }}
              onClick={() => moveCardsLeft()}
            />

            {[...cards].map((cardId: number) => {
              return (
                <div
                  key={`key-link-${cardId}`}
                  style={{
                    height: cardId === activeCard ? "40px" : "40px",
                    width: cardId === activeCard ? "40px" : "40px",
                    borderRadius: "50%",
                    border: cardId === activeCard ? "2px solid white" : "",
                    backgroundColor: getCardColor[cardId],
                    boxShadow: "0 0 10px 1px #e2e2e2",
                    cursor: "pointer",
                  }}
                  onClick={() => sortCards(cardId)}
                />
              );
            })}

            <div
              style={{
                height: "24px",
                width: "25px",
                background: "url(/images/arrow.svg)",
                margin: "3px 0 3px 0px",
                cursor: "pointer",
              }}
              onClick={() => moveCardsRight()}
            ></div>
          </Row>
          </Column>
        </MobileView>
    </>
  );
};

export default CardCarrousel;
